import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PlayVideo from "../PlayVideo/PlayVideo";
import './assets/styles/_index.scss';
import VideoImg from "../../images/off_plan_video_img.png"

const OffPlanVideo = (props) => {

    // Video play
    const [isPlay, setPlay] = useState(false)
    // Video play

    return (
        <section className="off-plan-video-wrapper">
            <Container>
                <Row>
                    <Col>
                        <div className="off-plan-video-img">
                            <div className="off-plan-video-play">
                                <button onClick={() => setPlay(true)} className="off-plan-play-button">
                                    <div className="off-plan-play-btn d-flex align-items-center justify-content-center">
                                        <i className="icon icon-play"></i>
                                    </div>
                                </button>
                                <PlayVideo
                                    isOpen={isPlay}
                                    stopPlay={setPlay}
                                    videoId=""
                                    isCloseFunction={setPlay}
                                    videourl={'https://youtu.be/6stlCkUDG_s?si=n8kv5T_DkQCWTNfq'}
                                    htmlink={""}
                                />
                            </div>
                            <img src={VideoImg} className="img-fluid" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OffPlanVideo