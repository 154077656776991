import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import './assets/styles/_index.scss';

const OffPlanPayment = (props) => {
    return (
        <section className="off-plan-payment-wrapper">
            <Container>
                <Row>
                    <Col>
                        <div class="property-desc-title">Payment Plan</div>
                    </Col>
                </Row>

                <div className="off-plan-payment-grid">
                    <div className="off-plan-payment-card">
                        <div className="off-plan-payment-title">20%</div>
                        <div className="off-plan-payment-text">After 20% complete</div>
                    </div>
                    <div className="off-plan-payment-card">
                        <div className="off-plan-payment-title">20%</div>
                        <div className="off-plan-payment-text">After 40% complete</div>
                    </div>
                    <div className="off-plan-payment-card">
                        <div className="off-plan-payment-title">20%</div>
                        <div className="off-plan-payment-text">After 60% complete</div>
                    </div>
                    <div className="off-plan-payment-card">
                        <div className="off-plan-payment-title">40%</div>
                        <div className="off-plan-payment-text">On handover</div>
                    </div>
                </div>
                {/* <Row>
                    <Col lg={3} md={6}>
                        <div className="off-plan-payment-card">
                            <div className="off-plan-payment-title">20%</div>
                            <div className="off-plan-payment-text">After 20% complete</div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="off-plan-payment-card">
                            <div className="off-plan-payment-title">20%</div>
                            <div className="off-plan-payment-text">After 40% complete</div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="off-plan-payment-card">
                            <div className="off-plan-payment-title">20%</div>
                            <div className="off-plan-payment-text">After 60% complete</div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="off-plan-payment-card">
                            <div className="off-plan-payment-title">40%</div>
                            <div className="off-plan-payment-text">On handover</div>
                        </div>
                    </Col>
                </Row> */}
            </Container>
        </section>
    )
}

export default OffPlanPayment