import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import loadable from "@loadable/component";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import './assets/styles/_index.scss';
import FormFields from "../../forms/offplan_detail_vertical_form.json";
const Newsletter = loadable(() => import("../forms/default-form-layout"));
const OffPlanDesc = (props) => {

    // Sticky scroll
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50)
        })
    }, [])
    // Sticky scroll

    const prop_url = props?.pageurl
    const prop_address = props?.display_address
    const prop_img_url = props?.propImg && props.propImg?.length > 0 ? props.propImg[0] : ""

    return (
        <section className="off-plan-desc-wrapper" id="description">
            <Container>
                <Row>
                    <Col xl={7}>
                        <div className="off-plan-desc-details">
                            {!props.completion_date &&
                                <div>
                                    <div class="desc-key-wrapper">
                                        <div class="desc-key-title">Completion Date</div>
                                        <div class="desc-key-text">Q4 2024</div>
                                    </div>
                                    <div className="border-line key-details"></div>
                                </div>
                            }
                            {!props.developer &&
                                <div>
                                    <div class="desc-key-wrapper">
                                        <div class="desc-key-title">Developer</div>
                                        <div class="desc-key-text">Emaar Properties</div>
                                    </div>
                                    <div className="border-line key-details"></div>
                                </div>
                            }
                            {props.display_address &&
                                <div>
                                    <div class="desc-key-wrapper">
                                        <div class="desc-key-title">Location</div>
                                        <div class="desc-key-text">{props.display_address}</div>
                                    </div>
                                </div>
                            }
                            {!props.payment_plan &&
                                <div>
                                    <div class="desc-key-wrapper">
                                        <div class="desc-key-title">Payment Plan</div>
                                        <div class="desc-key-text">20/20/20/40</div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="border-line key-details-lg"></div>

                        <div className="off-plan-desc">
                            <div className="property-desc-title">Description</div>
                            <p>St. Leonards Square is an exclusive new development of just 4 three-bedroom semi-detached homes, set on a private cul-de-sac in the popular area of Thrybergh. All four homes offer spacious accommodation set over three-storeys with off-street parking and large south-west facing gardens.<br /><br />
                                You enter each property through a light and airy hallway which leads to a spacious lounge. To the rear of the property, the contemporary open plan kitchen dining area is fitted with a range of integrated appliances. French doors lead out from this space onto a private enclosed rear garden. There is also the benefit of a convenient downstairs WC.<br /><br />
                                To the first floor are two well-proportioned bedrooms and a fully tiled luxurious family bathroom suite. The Principal bedroom suite is situated on a floor of its own. There is a fully tiled en-suite shower room with feature sky light windows flooding the room with natural light. Subject to early reservation, buyers will get a choice of tile in both the main bathroom and en-suite to suit their personal taste.<br /><br />
                                A paved driveway provides off road parking for 2 vehicles and an electric car-charging point is provided to meet modern day needs. The properties have been built to a high standard throughout, with 'Smartroof' technology used to maximise space and offer consistent thermal performance. All homes are fitted with an alarm for that added sense of security.</p>
                        </div>
                    </Col>
                    <Col xl={1}></Col>
                    <Col xl={4}>
                        <div className={`off-plan-detail-sidebar position-sticky ${scroll ? "scrolled" : ""}`} id="off-plan-detail-sidebar">
                            <div className="news-detail-subscribe-wrapper">
                                <div className="news-detail-subscribe-content">
                                    <h5>Register your interest</h5>
                                    <p>Share your details below and we’ll be in touch to discuss this development.</p>
                                    <Newsletter
                                        formStyle={"vertical"}
                                        fields={FormFields}
                                        classname="get-started-form newsletter"
                                        formtagclassname=""
                                        prop_url={`${prop_url}`}
                                        prop_address={`${prop_address}`}
                                        prop_img_url={`${prop_img_url}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OffPlanDesc